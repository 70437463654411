import React, { useState, useEffect } from "react";
import { fabric } from "fabric";

import { fetchArtwork } from "../artworkService";
import { addImageOrSvg } from "../../library/imageActions";

type ArtworkPanelProps = {
  canvas: fabric.Canvas;
  activeObject: fabric.Object | null;
  handleCollapse: (value: boolean) => void;

};

const ArtworkPanel: React.FC<ArtworkPanelProps> = ({
  canvas,
  activeObject,
  handleCollapse
}) => {
  const [serverImages, setServerArtwork] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getArtworks = async () => {
      setIsLoading(true);

      try {
        const data = await fetchArtwork();
        setServerArtwork(data);
      } catch (error) {
        console.error("Failed to fetch images:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getArtworks();
  }, []);

  const handleArtwork = (imagePath) => {
    addImageOrSvg(canvas, imagePath);
  };

  return (
    <div className="card text-center">
        <div className="card-header">
          Artwork <button type="button" className="btn-close float-end" aria-label="Close" onClick={()=>handleCollapse(true)} ></button>
        </div>
        <div className="card-body h-full">
      <div className="container p-3">
        {!isLoading ? (
          <div className="row">
            {serverImages?.map((image) => (
              <div
                key={'serverImage'+image.id}
                className="col-12 col-sm-6 col-md-4 col-lg-4 mb-4"
              >
                <div className="card">
                  <img
                    src={
                      process.env.REACT_APP_AWS_BASE_URL + image.thumbnail_path
                    }
                    className="card-img-top img-thumbnail"
                    alt={`${image.id}`}
                    onClick={() =>
                      handleArtwork(
                        process.env.REACT_APP_AWS_BASE_URL + image.image_path
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default ArtworkPanel;
