import axios from 'axios';

// Base URL for your API
const BASE_URL = 'https://app.yeswefulfill.com/Api';

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Authorization':'Bearer vNUrDAz9zoJkJpZpuaqEb2IV8wF8jEQZ',
    // 'Content-Type': 'application/json', 
  },
});

// Interceptors can be added here to handle authentication or global errors if needed
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle global errors, for example, logging out if the token is invalid
    return Promise.reject(error);
  }
);

export default apiClient;