import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import ColorPicker from "../ColorPicker";

type ColorPanelProps = {
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;
  bgSvg: fabric.Object | fabric.Group | null;
  handleCollapse: (value: boolean) => void;
};

const ColorPanel: React.FC<ColorPanelProps> = ({
  canvas,
  bgSvg,
  handleCollapse,
}) => {
  const [productColor, setProductColor] = useState("#FFFFFF");
  const [productParts, setProductParts] = useState({
    "Left_Front_Panel_Layer":"",
    "Left_Back_Panel_Layer":"",
    "Right_FRONT_Panel_Layer":"",
    "Right_Back_Panel_Layer": ""
  });


  const parts = [
    "Left_Front_Panel_Layer",
    "Left_Back_Panel_Layer",
    "Right_FRONT_Panel_Layer",
    "Right_Back_Panel_Layer",
  ];
  useEffect(()=>{


    if (bgSvg instanceof fabric.Group) {

      bgSvg._objects.forEach((obj: fabric.Object) => {
        productParts[(obj as any).id as string] =  obj.get("fill")
        if (parts.includes((obj as any).id)) {
          setProductParts(productParts)
        }
      })
    }
  },[])
  

  const handleColorChange = (newColor: string) => {
    setProductColor(newColor);

    if (bgSvg instanceof fabric.Group) {
      bgSvg._objects.forEach((obj: fabric.Object) => {
        if (obj) {
          if (parts.includes((obj as any).id)) {
            obj.set("fill", newColor);
          }
        }
      });

      // console.log(canvas,"Can")
      canvas?.renderAll();
    }

    canvas?.renderAll();
  };

  const getObjectById = (part: string): fabric.Object | undefined => {
    if (bgSvg instanceof fabric.Group) {
      return bgSvg._objects.find((obj: fabric.Object) => {
        if (obj && (obj as any).id.toLowerCase() === part.toLowerCase()) {
          return obj;
        }
      });
    }
  };
  const handlePartColor = (newColor: string, part) => {
    const obj = getObjectById(part);
    obj && obj.set("fill", newColor);
    canvas?.renderAll();
  };
  return (
    <div className="card text-center">
      <div className="card-header">
        Colors{" "}
        <button
          type="button"
          className="btn-close float-end"
          aria-label="Close"
          onClick={() => handleCollapse(true)}
        ></button>
      </div>
      <div className="card-body">
        <div className="color-panel">
          <div className=" px-3 py-2 ">
            <div className="justify-content-center d-flex mt-3">
              <button
                className="btn btn-outline-secondary  "
                style={{
                  backgroundColor: "white",
                  boxShadow: "none",
                  padding: "1px",
                }}
                type="button"
              >
                <ColorPicker
                  color={productColor}
                  onChange={(event) => handleColorChange(event)}
                />
              </button>
            </div>
            <div className="justify-content-center d-flex">Product Color</div>
          </div>

          {parts.map((part, index) => {
            const obj = getObjectById(part);
            const fillColor = obj && obj.get("fill");

            return (
              <div key={"part" + index} className=" px-3 py-2 ">
                <div className="justify-content-center d-flex mt-3">
                  <button
                    className="btn btn-outline-secondary  "
                    style={{
                      backgroundColor: "white",
                      boxShadow: "none",
                      padding: "1px",
                    }}
                    type="button"
                  >
                    <ColorPicker
                      color={productParts[part]}
                      onChange={(event) => handlePartColor(event, part)}
                    />
                  </button>
                </div>
                <div className="justify-content-center d-flex">
                  {part.replaceAll("_", " ")}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ColorPanel;
