import React, { useState, useRef, useEffect } from "react";
import { fabric } from "fabric";
import { addImageFromUrl } from "../../library/imageActions";
import ImageLibraries, {
  PixabayImage,
  PexelsImage,
} from "../../library/imageLibraries";
import { uploadImage, fetchImages } from "../imageService";

type ImagePanelProps = {
  canvas: fabric.Canvas | null;
  handleCollapse: (value: boolean) => void;
};

const ImagePanel: React.FC<ImagePanelProps> = ({ canvas, handleCollapse }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [serverImages, setServerImages] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPixelLoading, setIsPixelLoading] = useState<boolean>(false);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);

  const [selectedLibrary, setSelectedLibrary] = useState<"pixabay" | "pexels">(
    "pixabay"
  );
  const [imageList, setImageList] = useState<(PixabayImage | PexelsImage)[]>(
    []
  );

  const UploadButtonRef = useRef<HTMLInputElement | null>(null);
  const getImages = async () => {
    setIsLoading(true);
    try {
      const data = await fetchImages();
      setServerImages(data); // Store images from the API
    } catch (error) {
      console.error("Failed to fetch images:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // load images on default load
  useEffect(() => {
    getImages();
  }, []);

  useEffect(() => {
    if (isUploaded) {
      getImages();
     
    }
  }, [isUploaded]);

  const searchImages = async () => {
    setIsPixelLoading(true);
    try {
      const imageLibrary = new ImageLibraries(selectedLibrary);
      const images = await imageLibrary.searchImages(searchQuery);
      setImageList(images);
    } catch (error) {
      console.error("Error searching images:", error);
    }finally {
      setIsPixelLoading(false);
    }
  };

  const handleAddImage = (image: PixabayImage | PexelsImage) => {
    if (canvas) {
      addImageFromUrl(
        canvas,
        "webformatURL" in image ? image.webformatURL : image.src.original
      ); // Use the appropriate property based on the image source
    }
  };

  const handleGeneratedImage = (imageURL) => {
    if (canvas) {
      addImageFromUrl(canvas, imageURL); // Use the appropriate property based on the image source
    }
  };

  const handleAddClick = () => {
    if (UploadButtonRef.current) UploadButtonRef.current?.click();
  };

  const handleUploadButton = (event) => {
    const file = event.target.files[0];
    let selectedImage;
    let scalingFactor;

    if (file) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        if (e && e.target !== null) selectedImage = e.target.result;
        // fabric.Image.fromURL(selectedImage, function (img) {
        //   if (canvas && canvas.width !== undefined) {
        //     scalingFactor = canvas.width - canvas.width * 0.3;
        //     img.scaleToWidth(scalingFactor);
        //     img.scaleToHeight(scalingFactor);
        //     img.set({ top: 220 });
        //     canvas.add(img);
        //     canvas.setActiveObject(img);
        //     canvas.renderAll();
        //   }
        // });

        try {
          let name = `${Math.random().toString(36).substring(2, 8)}`;
          const response = await uploadImage(file, name);
          setIsUploaded(true);
          console.log("Image uploaded successfully:", response);
        } catch (error) {
          console.error("Failed to upload image:", error);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="card text-center">
      <div className="card-header">
        Images{" "}
        <button
          type="button"
          className="btn-close float-end"
          aria-label="Close"
          onClick={() => handleCollapse(true)}
        ></button>
      </div>
      <div className="card-body">
        <ul className="nav nav-tabs" id="myTabs">
          <li className="nav-item">
            <a
              className="nav-link py-4 fw-light active"
              id="tab2-tab"
              data-bs-toggle="tab"
              href="#tab2"
            >
              UPLOAD
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link  py-4 fw-light"
              id="tab1-tab"
              data-bs-toggle="tab"
              href="#tab1"
            >
              IMAGES
            </a>
          </li>
        </ul>

        <div className="tab-content px-3 py-3" id="myTabContent">
          <div
            className="tab-pane fade"
            id="tab1"
            role="tabpanel"
            aria-labelledby="tab1-tab"
          >
            <div className="container-fluid">
              <div className="input-group mb-3">
                <button
                  className="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedLibrary === "pixabay" ? "Pixabay" : "Pexels"}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => setSelectedLibrary("pixabay")}
                    >
                      Pixabay
                    </a>
                  </li>
                </ul>
                <input
                  type="text"
                  aria-describedby="inputGroupFileAddon04"
                  className="form-control"
                  placeholder="Search Images"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button
                  id="inputGroupFileAddon04"
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={searchImages}
                >
                  Search
                </button>
              </div>
              {(imageList.length == 0) && <div className="d-flex justify-content-center px-2">
                <h5>Please search for anything using seach input</h5>
              </div>}
              {isPixelLoading && (<div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>)}
              <div className="row row-cols-2 searchpanel">
             
                {imageList.map((image, index) => (
                  <div key={image.id + "_" + index} className="col mb-4">
                    <img
                      src={
                        "webformatURL" in image
                          ? image.webformatURL
                          : image.src.original
                      }
                      alt={image.tags}
                      className="img-fluid"
                      onClick={() => handleAddImage(image)}
                    />
                  </div>
                ))}
              </div>
             
            </div>
          </div>
          <div
            className="tab-pane fade show active"
            id="tab2"
            role="tabpanel"
            aria-labelledby="tab2-tab"
          >
            <div className="container-fluid">
              <div className="input-group mb-3">
                {/* <label className="input-group-text">Upload</label> */}
                <input
                  onChange={handleUploadButton}
                  ref={UploadButtonRef}
                  type="file"
                  id="imageInput"
                  accept="image/*"
                  className="form-control"
                />
              </div>

              <div className="container">
                {!isLoading ? (
                  <div className="row">
                    {serverImages.length == 0 && (
                      <div className="d-flex justify-content-center px-2">
                        <h5>Please upload images from your device</h5>
                      </div>
                    )}
                    {serverImages?.map((image, index) => (
                      <div
                        key={image.name + "_" + index}
                        className="col-12 col-sm-6 col-md-4 col-lg-4 mb-4"
                      >
                        <div className="card">
                          <img
                            src={
                              process.env.REACT_APP_AWS_BASE_URL +
                              image.thumbnail_path
                            }
                            className="card-img-top"
                            alt={`${image.id}`}
                            onClick={() =>
                              canvas &&
                              addImageFromUrl(
                                canvas,
                                process.env.REACT_APP_AWS_BASE_URL +
                                  image.thumbnail_path
                              )
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePanel;
