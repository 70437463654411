export const PROPS_TO_INCLUDE_IN_CANVAS = [
    "id",
    "name",    
    "metatype",
    "scaleX",
    "scaleY",
    "objectCaching",
    "isLocked",
    "lockMovementX",
    "lockMovementY",
    "lockScalingX",
    "lockScalingY",
    "lockRotation",
    "isWrapping",
    "listType",
    "listBullet",
    "listStyle",
    "listCounter",
    "listNull",
    "fontStyle",
    "family",
    "fontFamily",
    "setActiveStyle",
    "fontSize",
    "fontWeight",
    "lineHeight",
    "originX",
    "originY",
    "breakWords",
    "_renderTextLine",
    "height",
    "width",
    "editable",
    "includeDefaultValues",
    "textLines",
    "_charBounds",
    "_eventListeners",
    "_textLines",
    "applyFilters",
    "filters",
    "_dimensionAffectingProps",
    "_lineHeights",
    "_lineWidths",
    "_text",
    "_unwrappedTextLines",
    "_renderChars",
    "_renderTextLine",
    "isEndOfWrapping",
    "textAlign",
    "zoomX",
    "zoomY",
    "oCoords",
    "isRendering",
    "charSpacing",
    "textAlign",
    "styles",
    "path",
    "pathStartOffset",
    "pathSide",
    "pathAlign",
    "backgroundColor",
    "background"
];



export const POPULAR_FONTS = [
    ["Arial, sans-serif", "Arial"],
    ["Bevan, cursive", "Bevan"],
    ["Calibri, sans-serif", "Calibri"],
    ["Cinzel, serif", "Cinzel"],
    ["EB Garamond, serif", "EB Garamond"],
    ["Exo, sans-serif", "Exo"],
    ["Gentium Basic, serif", "Gentium Basic"],
    ["Lato, sans-serif", "Lato"],
    ["Open Sans, sans-serif", "Open Sans"],
    ["Palatino, serif", "Palatino"],
    ["Raleway, sans-serif", "Raleway"],
    ["Roboto Slab, serif", "Roboto Slab"],
    ["Sail, cursive", "Sail"],
    ["Alex Brus, cursive", "Alex Brush"],
    ["Lovers Quarrel, cursive", "Lovers Quarrel"],
    ["Oswald, sans-serif", "Oswald"],
    ["Montserrat Alternates, sans-serif", "Montserrat Alternates"],
    ["Nova Round, cursive", "Nova Round"],
    ["Supermercado One, cursive", "Supermercado One"],
    ["Playfair Display SC, serif", "Playfair Display SC"],
    ["Cutive, serif", "Cutive"],
    ["Rye, cursive", "Rye"],
    ["Crafty Girls, cursive", "Crafty Girls"],
    ["Metal Mania, cursive", "Metal Mania"],
    ["Metamorphous, cursive", "Metamorphous"],
    ['"Orbitron", sans-serif', "Orbitron"],
    ["VT323, monospace", "VT323"],
    ["Rock Salt, cursive", "Rock Salt"],
    ["Special Elite, cursive", "Special Elite"],
    ["Arya, sans-serif", "आर्या"],
    ["Halant, sans-serif", "हलन्त"],
  ];

export const EXTRA_TEXT_GUIDE = "exta-text-guide"
export const OUTLINE_LAYER_TO_CLIP = "outline-layer-to-clip"
export const LINES_AND_STITCHES = "lines-and-stitches"
export const FOLDING_AREA = "folding-area"
export const AREA_LABELS = "area-label"

export const OUTLINE_LAYERS_GROUP_PANEL  = "outline_panel"
export const GUIDELINE_OVERLAY_GROUP_PANEL  = "guideline_overlay_panel"


export const  TEMPLATE_BASE_COLOR = "#000001";