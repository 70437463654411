import { useEffect, useState, useRef, createContext} from "react";
import CanvasEvents from "./library/canvasEvents";

import LeftSideBar from "./components/leftSidebar/LeftSideBar";
import TextToolBox from "./components/objectToolBox/TextToolBox";
import ImageToolBox from "./components/objectToolBox/ImageToolBox";

import "./App.css";
import ArtworkToolbox from "./components/leftSidebar/ArtworkToolbox";
import ContainerWidth from "./components/ContainerWidth";

interface IResolution{
  width: number,
 height: number
}
interface IProductOptions {
  product_type: string
  svg_name: string
  svg_path:string
  final_resolution:IResolution,
  dpi:number,
  demo_resolution:IResolution
}
export const MyContext = createContext<IProductOptions>({
  product_type: "sneaker",
  svg_name: "/sneakers/SneakerTemplate.svg",
  svg_path:"/sneakers/SneakerTemplate.svg",
  final_resolution:{width: 3000, height: 2500},
  dpi:300,
  demo_resolution:{width: 600, height: 500}
});


const  App = ()=> {


const productOptions:IProductOptions = {
  product_type: "Sneaker",
  svg_name: "/sneakers/SneakerTemplate.svg",
  svg_path:"/sneakers/SneakerTemplate.svg",
  final_resolution:{width: 3000, height: 2500},
  dpi:300,
  demo_resolution:{width: 600, height: 500}
}
  console.log('productOptions', productOptions)

  const [collapsed, isCollapsed] = useState<boolean>(false);
  const [isVisible, resetVisibility] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string | null>(null);
  const [zoom, setZoom] = useState<number>(0.9); // initial zoom level is 100%


  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [bgSvg, setBgSvg] = useState<fabric.Object | fabric.Group | null>(null);

  const [activeObject, setActiveObject] = useState<fabric.Object | null>(null);

  let canvasEventsRef = useRef<CanvasEvents | null>(null);

  useEffect(() => {
    if (canvas) {
      canvasEventsRef.current = new CanvasEvents(canvas, setActiveObject);
      return () => {
        canvasEventsRef.current?.destroy();
      };
    }
  }, [canvas]);

  return (
    <MyContext.Provider value={productOptions}>
    <div className="w-full h-full">
      {/* <div className="shadow bottom position-fixed">
        <TopBar
          canvas={canvas}
          tempCan={tempCan}
          canvasEventsRef={canvasEventsRef}
          bgSvg={bgSvg}
          resetVisibility={resetVisibility}
          setAlertText={setAlertText}
          zoom={zoom}
          setZoom={setZoom}
        />
      </div> */}
      {activeObject?.type === "textbox" && (
        <div
          className={` p-2 text-tool-bar d-flex position-fixed w-100   ${
            activeObject?.type === "textbox" ? "border-bottom" : null
          }`}
        >
          <TextToolBox
            canvas={canvas}
            activeObject={activeObject}
            collapsed={collapsed}
          />
        </div>
      )}
      {activeObject?.type === "image" && (
        <div
          className={` p-2 text-tool-bar d-flex position-fixed w-100   ${
            activeObject?.type === "image" ? "border-bottom" : null
          }`}
        >
          <ImageToolBox
            canvas={canvas}
            activeObject={activeObject}
            collapsed={collapsed}
          />
        </div>
      )}
      {activeObject?.type === "path" && (
        <div
          className={` p-2 text-tool-bar d-flex position-fixed w-100   ${
            activeObject?.type === "path" ? "border-bottom" : null
          }`}
        >
          <ArtworkToolbox
            canvas={canvas}
            activeObject={activeObject}
            collapsed={collapsed}
          />
        </div>
      )}
      {/* <div  className="p-2 activeBackgroundButton">
        <ActiveBackground  canvas={canvas}
            activeObject={activeObject}
            collapsed={collapsed}/>
      </div> */}
      <div className="  left-sidebar position-fixed ">
        <LeftSideBar
          canvas={canvas as fabric.Canvas}
          activeObject={activeObject}
          isCollapsed={isCollapsed}
          bgSvg={bgSvg}
        />
      </div>

      <div className="row">
        <div className="col-11 offset-lg-1 col-md-11 offset-md-1 col-sm-10 offset-sm-2">
          <ContainerWidth
            activeObject={activeObject}
            canvas={canvas}
            setCanvas={setCanvas}
            setBgSvg={setBgSvg}
            resetVisibility={resetVisibility}
            isVisible={isVisible}
            alertText={alertText}
            canvasEventsRef={canvasEventsRef}
            bgSvg={bgSvg}
            zoom={zoom}
            setZoom={setZoom}
          />
        </div>
      </div>
    </div>
    </MyContext.Provider>
  );
}

export default App;
