import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGripVertical,
  faEllipsisV,
  faLock,
  faUnlock,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { GUIDELINE_OVERLAY_GROUP_PANEL, OUTLINE_LAYERS_GROUP_PANEL } from "../../utils/constants";

type LayerPanelProps = {
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;
  handleCollapse: (value: boolean) => void;

};

const LayerPanel: React.FC<LayerPanelProps> = ({ canvas, handleCollapse }) => {
  const [displayObjects, setDisplayObjects] = useState<any>([]);
  const [popoverVisible, setPopoverVisible] = useState<boolean[]>([]);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [dropIndex, setDropIndex] = useState<number | null>(null);

  const toggleLock = (index: number) => {
    const canvasObject = canvas?.getObjects()[index];
    if (canvasObject) {
      canvasObject.set({
        lockMovementX: !canvasObject.lockMovementX,
        lockMovementY: !canvasObject.lockMovementY,
        lockRotation: !canvasObject.lockRotation,
        lockScalingX: !canvasObject.lockScalingX,
        lockScalingY: !canvasObject.lockScalingY,
        lockScalingFlip: !canvasObject.lockScalingFlip,
        lockSkewingX: !canvasObject.lockSkewingX,
        lockSkewingY: !canvasObject.lockSkewingY,
        selectable: !canvasObject.selectable,
      });

      canvas?.renderAll();
    }
  };

  const toggleHide = (index: number) => {
    const canvasObject = canvas?.getObjects()[index];
    if (canvasObject) {
      canvasObject.set({
        visible: !canvasObject.visible,
      });

      canvas?.discardActiveObject();
      canvas?.renderAll();
    }
  };

  const togglePopover = (index: number) => {
    const updatedPopover = [...popoverVisible];
    updatedPopover[index] = !updatedPopover[index];
    setPopoverVisible(updatedPopover);
  };

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
    const draggedObject = displayObjects[index];
    canvas?.setActiveObject(draggedObject);
    canvas?.renderAll();
  };

  const handleDrop = (event: React.DragEvent<HTMLLIElement>, index: number) => {
    event.preventDefault();
    if (draggedIndex === null) return;

    const newDisplayObjects = [...displayObjects];
    const [draggedObject] = newDisplayObjects.splice(draggedIndex, 1);
    newDisplayObjects.splice(index, 0, draggedObject);

    setDisplayObjects(newDisplayObjects);
    setDraggedIndex(null);
    setDropIndex(null);

    canvas?.clear();
    newDisplayObjects.forEach((obj) => canvas?.add(obj));
    canvas?.renderAll();
  };

  const handleDragOver = (event: React.DragEvent<HTMLLIElement>, index: number) => {
    event.preventDefault();
    if (draggedIndex !== null && draggedIndex !== index) {
      setDropIndex(index);
    }
  };

  useEffect(() => {
    if (canvas) {
      canvas.on("after:render", () => {
        const objs = canvas.getObjects();
        setDisplayObjects(objs);
        setPopoverVisible(new Array(objs.length).fill(false));
      });
    }
  }, [canvas]);

  const handleClick = (index) => {
    const obj = displayObjects[index];
  
    if (canvas) {
      canvas.setActiveObject(obj);
      canvas.renderAll(); 
    }
  
  };

  
  return (
    <div className="card text-center">
    <div className="card-header">
      Layers <button type="button" className="btn-close float-end" aria-label="Close" onClick={()=>handleCollapse(true)} ></button>
    </div>
    <div className="card-body h-full">


    <div className="pe-2">
      <ul>
        {
(
  (displayObjects.length === 1) && <div className="d-flex justify-content-center px-2">
    <h5>Please add text, images, artwork etc to see layers here</h5>
  </div>
)
        }
        { (displayObjects.length > 1) && displayObjects.map((obj, index) => {
          if (obj.name === OUTLINE_LAYERS_GROUP_PANEL || obj.name === GUIDELINE_OVERLAY_GROUP_PANEL) return null;

          const isDragging = index === dropIndex;
          const isActive = canvas?.getActiveObject() === obj;
          return (
            <li
              key={index}
              draggable
              onClick={() => handleClick(index)}
              onDragStart={() => handleDragStart(index)}
              onDragOver={(event) => handleDragOver(event, index)}
              onDrop={(event) => handleDrop(event, index)}
              className={`no-bullets rounded-3 d-flex justify-content-between align-items-center   ${
                isDragging ? "drag-placeholder" : ""
              }`}
              style={{
                backgroundColor: isDragging
                  ? "#e0e0e0"
                  : isActive
                  ? "#f0f8ff" // Light blue background for active object
                  : "transparent",
                border: isDragging
                  ? "2px dashed #333"
                  : isActive
                  ? "2px solid #007bff" // Blue border for active object
                  : "0.5px solid grey",
              }}
            >
              <div className="d-flex align-items-center layer-font">
                <FontAwesomeIcon
                  icon={faGripVertical}
                  size="1x"
                  className="d-block mx-3"
                />
                {obj.type}-{obj.text || obj.id || index}
              </div>

              <div className="position-relative">
                {!obj.selectable ? (
                  <FontAwesomeIcon
                    icon={faUnlock}
                    className="mx-3"
                    onClick={() => toggleLock(index)}
                  />
                ) : (
                  <div>
                    <FontAwesomeIcon
                      icon={faEllipsisV}
                      className="mx-3"
                      onClick={() => togglePopover(index)}
                    />

                    {popoverVisible[index] && (
                      <div className="popover custom-popover">
                        <button
                          className="w-100 my-1"
                          onClick={() => toggleLock(index)}
                        >
                          <FontAwesomeIcon icon={faLock} /> Lock
                        </button>
                        <button
                          className="w-100 my-1"
                          onClick={() => toggleHide(index)}
                        >
                          {obj.visible ? (
                            <>
                              <FontAwesomeIcon icon={faEye} /> Hide
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faEyeSlash} /> Unhide
                            </>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
    </div>
    </div>
  );
};

export default LayerPanel;
