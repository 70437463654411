import React, { useState, useEffect } from "react";
import { fabric } from "fabric";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotateLeft,
  faRotateRight,
  faTurnUp,
  faTurnDown,
  faClone,
  faLayerGroup,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  rotateLeft,
  rotateRight,
  flipImageX,
  flipImageY,
  cloneObject,
  moveBackward,
  moveForward,
  bringToFront,
  sendToBack,
  deleteSelectedObject,
} from "../../library/imageActions";

type ImageToolBoxProps = {
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;
  collapsed: boolean | null;
};

const ImageToolBox: React.FC<ImageToolBoxProps> = ({
  canvas,
  activeObject,
  collapsed,
}) => {
  const [popover, setPopover] = useState(false);
  const handleFlipX = () => {
    if (canvas && activeObject instanceof fabric.Image) {
      flipImageX(canvas, activeObject);
    }
  };
  const handleFlipY = () => {
    if (canvas && activeObject instanceof fabric.Image) {
      flipImageY(canvas, activeObject);
    }
  };

  const handleRotateLeft = () => {
    if (canvas && activeObject instanceof fabric.Image) {
      rotateLeft(canvas, activeObject);
    }
  };
  const handleRotateRight = () => {
    if (canvas && activeObject instanceof fabric.Image) {
      rotateRight(canvas, activeObject);
    }
  };

  const handleClone = () => {
    if (canvas && activeObject instanceof fabric.Image) {
      cloneObject(canvas, activeObject);
    }
  };

  const togglePopover = () => {
    setPopover(!popover);
  };

  const handleFront = () => {
    if (canvas && activeObject instanceof fabric.Image) {
      moveForward(canvas, activeObject);
    }
  };

  const handleBack = () => {
    if (canvas && activeObject instanceof fabric.Image) {
      moveBackward(canvas, activeObject);
    }
  };

  const handleBringToFront = () => {
    if (canvas && activeObject instanceof fabric.Image) {
      bringToFront(canvas, activeObject);
    }
  };

  const handleSendToBack = () => {
    if (canvas && activeObject instanceof fabric.Image) {
      sendToBack(canvas, activeObject);
    }
  };


  const handleDelete = () => {
    if (canvas && activeObject instanceof fabric.Image) {
      deleteSelectedObject(canvas, activeObject)
    }
  };
  return (
    <div className="w-100 my-2">
      {activeObject instanceof fabric.Image && (
        <div
          className={`d-flex justify-content-center image-toolbox ${
            !collapsed ? "offset-2" : null
          }`}
        >
          <div>
            <button
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleRotateLeft();
              }}
            >
              <FontAwesomeIcon icon={faRotateLeft} />
            </button>
            <button
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleRotateRight();
              }}
            >
              <FontAwesomeIcon icon={faRotateRight} />
            </button>
          
            <button
              title="Flip-Y"
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleFlipY();
              }}
            >
              <FontAwesomeIcon icon={faTurnUp} />
            </button>
            <button
              title="Flip-X"
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleFlipX();
              }}
            >
              <FontAwesomeIcon icon={faTurnDown} />
            </button>
          
            <button
              className="btn btn-outline-secondary  btn-group"
              type="button"
              onClick={() => {
                handleClone();
              }}
            >
              <FontAwesomeIcon icon={faClone} />
            </button>
          
          
          
        </div>
          <div className="popover-container">
          <button
            type="button"
            className="btn btn-outline-secondary btn-group"
            onClick={togglePopover}
          >
            <FontAwesomeIcon icon={faLayerGroup} />
          </button>
          {popover && (
            <div className="popover popover-down">
              <div className="popover-body d-flex row">
                <div
                  onClick={handleFront}
                  className="col-12 justify-content-center d-flex my-1 clipto-border"
                >
                  <span>Forward</span>
                </div>
                <div
                  onClick={handleBack}
                  className="col-12 justify-content-center d-flex my-1 clipto-border"
                >
                  <span>Backward</span>
                </div>
                <div
                  onClick={handleBringToFront}
                  className="col-12 justify-content-center d-flex my-1 clipto-border"
                >
                  <span>Bring to front</span>
                </div>
                <div
                  onClick={handleSendToBack}
                  className="col-12 justify-content-center d-flex my-1 clipto-border"
                >
                  <span>Send to back</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <button className="btn btn-danger" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
        </div>
      )}
    </div>
  );
};

export default ImageToolBox;
