import React, { useState, useEffect } from "react";
import { fabric } from "fabric";
import {
  setFontSize,
  setFontWeight,
  setFontFamily,
  setFontStyle,
  setTextDecoration,
  setFontColor,
  flipTextX,
  flipTextY,
  rotateLeft,
  rotateRight,
  cloneObject,
  moveBackward,
  moveForward,
  bringToFront,
  sendToBack,
  deleteSelectedObject,
} from "../../library/textActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faItalic,
  faUnderline,
  faFont,
  faMinus,
  faStrikethrough,
  faRotateLeft,
  faRotateRight,
  faTurnUp,
  faTurnDown,
  faClone,
  faLayerGroup,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ColorPicker from "../ColorPicker";
import WebFont from "webfontloader";
import { POPULAR_FONTS, TEMPLATE_BASE_COLOR } from "../../utils/constants";

type TextToolBoxProps = {
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;
  collapsed: boolean | null;
};

const TextToolBox: React.FC<TextToolBoxProps> = ({
  canvas,
  activeObject,
  collapsed,
}) => {

  const [fonts] = useState(POPULAR_FONTS);
  const [selectedFont, setSelectedFont] = useState("Arial");

  useEffect(() => {
    WebFont.load({
      google: {
        families: [...fonts].map((font) => font[1]),
      },
      active: () => {
        // console.log('Fonts are loaded!');
      },
    });
  }, [fonts]);

  const [fontSize, _setFontSize] = useState(
    (activeObject as fabric.Textbox)?.fontSize || 16
  );
  const [fontWeight, _setFontWeight] = useState(
    (activeObject as fabric.Textbox)?.fontWeight || "normal"
  );
  const [fontStyle, _setFontStyle] = useState(
    (activeObject as fabric.Textbox)?.fontStyle || "normal"
  );
  const [fontColor, _setFontColor] = useState(TEMPLATE_BASE_COLOR);
  const [textDecoration, _setTextDecoration] = useState({
    underline: (activeObject as fabric.Textbox)?.underline || false,
    overline: (activeObject as fabric.Textbox)?.overline || false,
    linethrough: (activeObject as fabric.Textbox)?.linethrough || false,
  });

  const [popover, setPopover] = useState(false);

  const handleFontSizeChange = (value: number) => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      setFontSize(canvas, activeObject, value);
      _setFontSize(value);
    }
  };

  const handleFontWeightChange = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      let newWeight = activeObject.fontWeight === "bold" ? "normal" : "bold";
      setFontWeight(canvas, activeObject, newWeight);
      _setFontWeight(newWeight);
    }
  };

  const handleFontStyleChange = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      let newFontStyle =
        activeObject.fontStyle === "italic"
          ? "normal"
          : ("italic" as "normal" | "italic" | "oblique");
      setFontStyle(canvas, activeObject, newFontStyle);
      _setFontStyle(newFontStyle);
    }
  };

  const handleTextDecoration = (
    type: "underline" | "overline" | "linethrough"
  ) => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      const newStyle = !textDecoration[type];
      setTextDecoration(canvas, activeObject, type, newStyle);
      _setTextDecoration({
        ...textDecoration,
        [type]: newStyle,
      });
    }
  };

  const handleColorChange = (newColor: string) => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      setFontColor(canvas, activeObject, newColor);
      _setFontColor(newColor);
    }
  };

  const handleFlipX = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      flipTextX(canvas, activeObject);
    }
  };
  const handleFlipY = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      flipTextY(canvas, activeObject);
    }
  };

  const handleRotateLeft = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      rotateLeft(canvas, activeObject);
    }
  };
  const handleRotateRight = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      rotateRight(canvas, activeObject);
    }
  };

  const handleClone = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      cloneObject(canvas, activeObject);
    }
  };

  const togglePopover = () => {
    setPopover(!popover);
  };

  const handleFront = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      moveForward(canvas, activeObject);
    }
  };

  const handleBack = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      moveBackward(canvas, activeObject);
    }
  };

  const handleBringToFront = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      bringToFront(canvas, activeObject);
    }
  };

  const handleSendToBack = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      sendToBack(canvas, activeObject);
    }
  };

  const handleFontFamilyChange = (textValue) => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      setFontFamily(canvas, activeObject, textValue);
      setSelectedFont(textValue);
    }
  };

  useEffect(() => {
    if (activeObject instanceof fabric.Textbox) {
      _setFontSize(activeObject.fontSize || 16);
      _setFontWeight(activeObject.fontWeight || "normal");
      _setFontStyle(activeObject.fontStyle || "normal");
      _setFontColor(activeObject.fill?.toString() || TEMPLATE_BASE_COLOR);
      _setTextDecoration({
        underline: activeObject.underline || false,
        overline: activeObject.overline || false,
        linethrough: activeObject.linethrough || false,
      });
    }
  }, [activeObject]);


  const handleDelete = () => {
    if (canvas && activeObject instanceof fabric.Textbox) {
      deleteSelectedObject(canvas, activeObject)
    }
  };
  return (
    <div className="w-100 my-2">
      {activeObject instanceof fabric.Textbox && (
        <div
          className={`d-flex justify-content-center  ${
            !collapsed ? null : null
          }`}
        >
          <div className="">
            <select
              className="btn btn-outline-secondary  btn-group-fontfamily justify-content-start"
              value={activeObject ? activeObject?.fontFamily : selectedFont}
              onChange={(e) => handleFontFamilyChange(e.target.value)}
            >
              <optgroup label="Popular Fonts">
                {fonts.map(([cssFont, name], index) => (
                  <option
                    key={index+'_'+name}
                    value={cssFont}
                    style={{ fontFamily: cssFont }}
                  >
                    {name}
                  </option>
                ))}
              </optgroup>

              {/* <optgroup label="Google Fonts">
                {googleFonts.map((font) => (
                  <option key={font} value={font} style={{ fontFamily: font }}>
                    {font}
                  </option>
                ))}
              </optgroup> */}
            </select>
          </div>
          <div className=" mx-3   justify-content-center d-flex">
            <button
              className="btn btn-outline-secondary btn-group-fontsize"
              type="button"
              onClick={() => handleFontSizeChange(fontSize - 1)}
            >
              -
            </button>
            <input
              type="number"
              className="form-control text-center fontsize-input"
              value={fontSize}
              onChange={(e) => _setFontSize(Number(e.target.value))}
              onBlur={() => handleFontSizeChange(fontSize)}
            />
            <button
              className="btn btn-outline-secondary btn-group-fontsize"
              type="button"
              onClick={() => handleFontSizeChange(fontSize + 1)}
            >
              +
            </button>
          </div>
          <div className=" " aria-label="Text Styles">
            <button
              title="Bold"
              type="button"
              className={`btn-group btn ${
                fontWeight === "bold"
                  ? "btn-secondary"
                  : "btn-outline-secondary"
              }`}
              onClick={() => {
                handleFontWeightChange();
              }}
            >
              <FontAwesomeIcon icon={faBold} />
            </button>
            <button
              title="Italic"
              type="button"
              className={`btn-group btn ${
                fontStyle === "italic"
                  ? "btn-secondary"
                  : "btn-outline-secondary"
              }`}
              onClick={() => {
                handleFontStyleChange();
              }}
            >
              <FontAwesomeIcon icon={faItalic} />
            </button>
            <button
              title="Underline"
              type="button"
              className={`btn-group btn ${
                textDecoration.underline
                  ? "btn-secondary"
                  : "btn-outline-secondary"
              }`}
              onClick={() => {
                handleTextDecoration("underline");
              }}
            >
              <FontAwesomeIcon icon={faUnderline} />
            </button>
            <button
              title="Strikethrough"
              type="button"
              className={`btn-group btn-group btn ${
                textDecoration.linethrough
                  ? "btn-secondary"
                  : "btn-outline-secondary"
              }`}
              onClick={() => {
                handleTextDecoration("linethrough");
              }}
            >
              <FontAwesomeIcon icon={faStrikethrough} />
            </button>
            <button
              title="Overline"
              type="button"
              className={`btn-group  btn ${
                textDecoration.overline
                  ? "btn-secondary"
                  : "btn-outline-secondary"
              }`}
              onClick={() => {
                handleTextDecoration("overline");
              }}
            >
                {/* <FontAwesomeIcon icon={faSquare} className="fa-stack-1x " /> */}
                <FontAwesomeIcon
                  icon={faFont}
                  className="fa-stack-1x "
                />
                <FontAwesomeIcon
                  icon={faMinus}
                  className="fa-stack-1x font-minus"
                />
              
              {/* <span className="fa-stack">
                <FontAwesomeIcon icon={faSquare} className="fa-stack-1x " />
                <FontAwesomeIcon
                  icon={faFont}
                  className="fa-stack-1x fa-inverse "
                />
                <FontAwesomeIcon
                  icon={faMinus}
                  className="fa-stack-1x"
                  style={{ marginTop: -5 }}
                />
              </span> */}
            </button>
            <button
              className="btn btn-outline-secondary color-picker-button btn-group"
              type="button"
              style={{ backgroundColor: "white", boxShadow: "none" }}
            >
              <ColorPicker color={fontColor} onChange={handleColorChange} />
            </button>
            <button
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleRotateLeft();
              }}
            >
              <FontAwesomeIcon icon={faRotateLeft} />
            </button>
            <button
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleRotateRight();
              }}
            >
              <FontAwesomeIcon icon={faRotateRight} />
            </button>
            <button
              title="Flip-Y"
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleFlipY();
              }}
            >
              <FontAwesomeIcon icon={faTurnUp} />
            </button>
            <button
              title="Flip-X"
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleFlipX();
              }}
            >
              <FontAwesomeIcon icon={faTurnDown} />
            </button>
            <button
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleClone();
              }}
            >
              <FontAwesomeIcon icon={faClone} />
            </button>
          
          </div>

            <div className="popover-container">
              <button
                type="button"
                className="btn btn-outline-secondary btn-group"
                onClick={togglePopover}
              >
                <FontAwesomeIcon icon={faLayerGroup} />
              </button>
              {popover && (
                <div className="popover popover-down">
                  <div className="popover-body d-flex row">
                    <div
                      onClick={handleFront}
                      className="justify-content-center d-flex my-1 clipto-border"
                    >
                      <span>Forward</span>
                    </div>
                    <div
                      onClick={handleBack}
                      className=" justify-content-center d-flex my-1 clipto-border"
                    >
                      <span>Backward</span>
                    </div>
                    <div
                      onClick={handleBringToFront}
                      className=" justify-content-center d-flex my-1 clipto-border"
                    >
                      <span>Bring to front</span>
                    </div>
                    <div
                      onClick={handleSendToBack}
                      className=" justify-content-center d-flex my-1 clipto-border"
                    >
                      <span>Send to back</span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <button className="btn btn-danger" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
        </div>
      )}
    </div>
  );
};

export default TextToolBox;
