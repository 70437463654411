import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { fabric } from "../../library/fabricLibrari";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVectorSquare,
  faFileText,
  faGripLinesVertical,
  faRotateLeft,
  faRotateRight,
  faSearchPlus,
  faSearchMinus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import CanvasEvents from "../../library/canvasEvents";
import { MyContext } from "../../App";
import {
  AREA_LABELS,
  EXTRA_TEXT_GUIDE,
  FOLDING_AREA,
  GUIDELINE_OVERLAY_GROUP_PANEL,
  LINES_AND_STITCHES,
  OUTLINE_LAYER_TO_CLIP,
  OUTLINE_LAYERS_GROUP_PANEL,
  PROPS_TO_INCLUDE_IN_CANVAS,
} from "../../utils/constants";
import {
  checkIfEmptyDesignArea,
  showOnlyTemplateOutline,
} from "../../library/exportActions";
import RightSideBar from "../RightSideBar";

interface SneakerProps {
  zoom: number;
  canvas: fabric.Canvas | null;
  canvasEventsRef: MutableRefObject<CanvasEvents | null>;
  isVisible: boolean;
  alertText: string | null;
  centerAreaWidth?: number;
  centerAreaHeight?: number;
  setCanvas: React.Dispatch<React.SetStateAction<fabric.Canvas | null>>;
  setZoom: React.Dispatch<React.SetStateAction<number>>;

  setBgSvg: React.Dispatch<
    React.SetStateAction<fabric.Object | fabric.Group | null>
  >;
  resetVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sneaker = ({
  zoom,
  canvas,
  canvasEventsRef,
  isVisible,
  alertText,
  centerAreaHeight,
  centerAreaWidth,
  setCanvas,
  setZoom,
  setBgSvg,
  resetVisibility,
}: SneakerProps) => {
  const canvas_2dref = useRef<HTMLCanvasElement>(null);
  const { svg_path } = useContext(MyContext);
  const originalWidth = useRef<number | null>(null);
  const originalHeight = useRef<number | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [isPanningEnabled, setIsPanningEnabled] = useState<boolean>(false);
  const [svgHeight, setSvgHeight] = useState<number>(0);
  const [svgWidth, setSvgWidth] = useState<number>(0);
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [showLabel, setShowLabel] = useState<boolean>(true);
  const [showFoldingLines, setShowFoldingLines] = useState<boolean>(true);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [localSvg, setLocalSvg] = useState<fabric.Object | fabric.Group | null>(
    null
  );

  function downloadCanvasAsImage(canvas, filename, format = "png", dpi = 300) {
    checkIfEmptyDesignArea(canvas)
      .then(() => {
        // Calculate scale factor to achieve desired DPI
        
        canvas.clone(async (newCanvas: fabric.Canvas) => {
          const scaleFactor = dpi / 96; // Default browser DPI is 96
          // removing all other objects from canvas
          await showOnlyTemplateOutline(newCanvas);

          newCanvas.renderAll();
          // Scale up the temporary canvas
          const originalWidth = canvas.width;
          const originalHeight = canvas.height;

          // Temporarily scale the Fabric canvas
          newCanvas.setWidth(originalWidth * scaleFactor);
          newCanvas.setHeight(originalHeight * scaleFactor);
          newCanvas.setZoom(scaleFactor);
          // Draw the scaled canvas onto the temporary canvas
          newCanvas.renderAll();

          // Convert to desired format and create download link
          const dataURL = newCanvas.toDataURL({
            format: `image/${format}`,
            quality: 1,
          });
          const link = document.createElement("a");
          link.href = dataURL;
          link.download = `${filename}.${format}`;
          link.click();

          // Clean up
          newCanvas.remove();
        }, PROPS_TO_INCLUDE_IN_CANVAS);
      })
      .catch((error) => {
        console.log("error", error);
        alert(error);
      });
  }

  // let svgHeight = 922;
  // let svgWidth = 1804;
  // useEffect(() => {
  //   if (isVisible) {
  //     setShowFoldingLines(true);
  //     setShowGrid(true);
  //     setShowLabel(true);
  //     resetVisibility(false);
  //   }
  // }, [isVisible]);

  useEffect(() => {
    if (!canvas) {
      fabric.loadSVGFromURL(
        svg_path,
        (objects, options) => {
          const svgImage: any = fabric.util.groupSVGElements(objects, options);

          if (svgImage.width && svgImage.height) {
            setSvgHeight(svgImage.height);
            setSvgWidth(svgImage.width);
          }
          const sneakerCanvas = new fabric.Canvas(canvas_2dref.current, {
            backgroundColor: "white",
          });

          const sneakCan = document.getElementById("container");

          sneakCan?.addEventListener("contextmenu", (e) => {
            e.preventDefault();
          });

          sneakerCanvas.renderAll();

          if (
            svgImage &&
            svgImage.width !== undefined &&
            svgImage.height !== undefined &&
            sneakerCanvas &&
            sneakerCanvas.width !== undefined &&
            sneakerCanvas.height !== undefined
          ) {
            let scale1 = sneakerCanvas.width / svgImage.width;
            let scale2 = sneakerCanvas.height / svgImage.height;
            svgImage.set({
              scaleX: scale1,
              scaleY: scale2,
              left: 0,
              top: 0,
              selectable: false,

              absolutePositioned: true,
              name: OUTLINE_LAYERS_GROUP_PANEL,
            });

            setBgSvg(svgImage);
            sneakerCanvas.add(svgImage);

           

            
            const handleObjects = () => {
              sneakerCanvas?.getObjects().forEach((obj) => {
                if (obj.name === GUIDELINE_OVERLAY_GROUP_PANEL) {
                  obj.set({ perPixelTargetFind: true, evented: false });
                  sneakerCanvas.bringToFront(obj);
                }
                if (obj.name !== OUTLINE_LAYERS_GROUP_PANEL) {
                  obj.set({ clipPath: svgImage });

                  obj.setCoords();
                }
              });
              setIsLoading(false);
            };

            sneakerCanvas?.off("after:render", handleObjects);
            sneakerCanvas?.on("after:render", handleObjects);
            setCanvas(sneakerCanvas);

            resizeCanvas(sneakerCanvas);
            originalWidth.current = sneakerCanvas.getWidth();
            originalHeight.current = sneakerCanvas.getHeight();

            // This is logic to add guideline overlay on every object
            setTimeout(() => {
              svgImage.clone((overlayImage) => {
                overlayImage.set({
                  perPixelTargetFind: true,
                  evented: false,
                  selectable: false,
                  name: GUIDELINE_OVERLAY_GROUP_PANEL,
                });
                overlayImage?._objects?.forEach((item) => {
                  if (item.metatype === OUTLINE_LAYER_TO_CLIP) {
                    item.set({ visible: false });
                  }
                });
                sneakerCanvas.add(overlayImage).renderAll();
                setLocalSvg(overlayImage);
              }, PROPS_TO_INCLUDE_IN_CANVAS);
              svgImage?._objects?.forEach((item) => {
                if (
                  item.metatype !== OUTLINE_LAYER_TO_CLIP &&
                  item.metatype !== EXTRA_TEXT_GUIDE
                ) {
                  item.set({ visible: false });
                }
              });
            }, 2000);
          }
        },
        (element, object) => {
          // Log the element to inspect attributes
          //console.log('Element:', element);

          // Try accessing `data-metatype`

          let metatype = element.getAttribute("data-metatype"); // Primary method

          if (!metatype && element.dataset) {
            metatype = element.dataset.metatype; // Fallback using dataset
          }

          if (metatype) {
            console.log("metatype", metatype);
            object.set("metatype", metatype);
            //console.log('data-metatype addded')
          } else {
            console.log("data-metatype not found for this element.");
          }
        }
      );
    }
  }, [svgHeight, svgWidth]);

  //   useEffect(()=>{
  // if(canvas){
  //     resizeCanvas(canvas)
  // }
  //   },[centerAreaWidth])

  useEffect(() => {
    if (canvas) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        resizeCanvas(canvas);
        canvas.renderAll();
      }, 20);
      canvas.renderAll();
    }

    // Cleanup function to clear timeout on component unmount or when dependencies change
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [centerAreaHeight, centerAreaWidth]);

  useEffect(() => {
    if (canvas) {
      let isDraggingLocal = false; // To track if dragging is active
      let lastPosXLocal = 0,
        lastPosYLocal = 0;

      // Handle mouse down event for panning
      const handleMouseDown = (event: any) => {
        if (isPanningEnabled === true) {
          isDraggingLocal = true;
          lastPosXLocal = event.e.clientX;
          lastPosYLocal = event.e.clientY;
          canvas.selection = false; // Disable selection while panning
        }
      };

      // Handle mouse move event for panning
      const handleMouseMove = (event: any) => {
        if (isPanningEnabled === true) {
          if (isDraggingLocal) {
            const deltaX = event.e.clientX - lastPosXLocal;
            const deltaY = event.e.clientY - lastPosYLocal;

            // Update the viewport transform for panning
            const vpt = canvas.viewportTransform;
            if (vpt) {
              vpt[4] += deltaX; // Update the X translation
              vpt[5] += deltaY; // Update the Y translation
              canvas.setViewportTransform(vpt); // Apply the new transform
            }

            lastPosXLocal = event.e.clientX; // Update last positions
            lastPosYLocal = event.e.clientY;
          }
        }
      };

      // Handle mouse up event to stop dragging
      const handleMouseUp = () => {
        isDraggingLocal = false; // Stop dragging
        canvas.selection = true; // Re-enable selection after panning
      };

      canvas.on("mouse:down", handleMouseDown);
      canvas.on("mouse:move", handleMouseMove);
      canvas.on("mouse:up", handleMouseUp);

      return () => {
        canvas.off("mouse:down", handleMouseDown);
        canvas.off("mouse:move", handleMouseMove);
        canvas.off("mouse:up", handleMouseUp);
      };
    }
  }, [isPanningEnabled, canvas]);

  const resizeCanvas = (sneakerCanvas) => {
    if (
      wrapperRef.current &&
      centerAreaHeight &&
      centerAreaWidth &&
      svgHeight &&
      svgWidth
    ) {
      let svgAspectRatio = svgHeight / svgWidth;
      let wrapperHeight, wrapperWidth;
      if (
        (centerAreaHeight * 0.15) / (centerAreaWidth * 0.85) >
        svgAspectRatio
      ) {
        wrapperHeight = centerAreaHeight * 0.75; // Height will be the limiting factor
        wrapperWidth = wrapperHeight / svgAspectRatio;
      } else {
        wrapperWidth = centerAreaWidth * 0.85; // Width will be the limiting factor
        wrapperHeight = wrapperWidth * svgAspectRatio;
      }

      // const wrapperWidth: number = centerAreaHeight * 0.75 * svgAspectRatio1;

      // const wrapperHeight: number = centerAreaWidth * 0.50 * svgAspectRatio2;

      // let svgAspectRatio =
      //   svgHeight < svgWidth ? svgHeight / svgWidth : svgWidth / svgHeight;
      // let ratioPercentage = svgAspectRatio;

      // const wrapperWidth: number =(centerAreaHeight * 0.75)<(centerAreaWidth*0.80)? (centerAreaWidth*0.80):(centerAreaWidth*0.80)*ratioPercentage;

      // const wrapperHeight: number = (centerAreaHeight * 0.75)>(centerAreaWidth*0.80)?(centerAreaHeight * 0.75):(centerAreaHeight *0.75)*ratioPercentage;

      // let svgAspectRatio =
      //   svgHeight < svgWidth ? svgHeight / svgWidth : svgWidth / svgHeight;

      let newHeight = wrapperHeight;
      let newWidth = wrapperWidth;

      sneakerCanvas.setWidth(newWidth);
      sneakerCanvas.setHeight(newHeight);

      if (originalHeight.current && originalWidth.current) {
        const scaleX = newWidth / originalWidth.current;
        const scaleY = newHeight / originalHeight.current;
        sneakerCanvas.getObjects().forEach((obj) => {
          obj.scaleX *= scaleX;
          obj.scaleY *= scaleY;
          obj.left *= scaleX;
          obj.top *= scaleY;
          obj.setCoords(); // Update object coordinates
        });
      }
      originalWidth.current = newWidth;
      originalHeight.current = newHeight;

      sneakerCanvas.renderAll();
    }
  };

  const handlePanningToggle = (event) => {
    setIsPanningEnabled(event.target.checked);
  };

  const handleGridChange = () => {
    // toggleObjByMetaType(canvas, [EXTRA_TEXT_GUIDE]);
    setShowGrid(!showGrid);
    if (localSvg instanceof fabric.Group) {
      localSvg._objects.forEach((obj: fabric.Object) => {
        if (obj) {
          if ((obj as any).metatype === LINES_AND_STITCHES) {
            obj.set("visible", !obj.visible);
          }
        }
      });

      canvas?.renderAll();
    }
  };

  const handleLabelVisibility = () => {
    setShowLabel(!showLabel);
    if (localSvg instanceof fabric.Group) {
      localSvg._objects.forEach((obj: fabric.Object) => {
        if ((obj as any).metatype === AREA_LABELS) {
          obj.set("visible", !obj.visible);
        }
      });

      canvas?.renderAll();
    }
  };

  const handleFoldingLinesVisibility = () => {
    setShowFoldingLines(!showFoldingLines);
    if (localSvg instanceof fabric.Group) {
      localSvg._objects.forEach((obj: fabric.Object) => {
        if ((obj as any).metatype === FOLDING_AREA) {
          obj.set("visible", !obj.visible);
        }
      });

      canvas?.renderAll();
    }
  };

  useEffect(() => {
    if (canvas) {
      const center = canvas.getCenter();
      canvas.zoomToPoint(new fabric.Point(center.left, center.top), zoom);
      if (canvas.viewportTransform)
        canvas.setViewportTransform(canvas.viewportTransform);
    }
  }, [zoom, canvas]);

  const handleUndo = () => {
    canvasEventsRef.current?.handleUndo();
  };

  const handleRedo = () => {
    canvasEventsRef.current?.handleRedo();
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => {
      const newZoom = Math.min(prevZoom * 1.1, 3);
      return newZoom;
    });
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => {
      const newZoom = Math.max(prevZoom * 0.9, 0.1); // min 10%
      return newZoom;
    });
  };

  const handleDelete = () => {
    canvasEventsRef.current?.deleteSelectedObject();
  };

  return (
    <div className="container pt-4" style={{ height: "100%" }}>
      <div className="d-flex  flex-column mb-3" style={{ height: "100%" }}>
        <div className="p-2">
          <div className="">
            <div
              className={`d-flex justify-content-center alertText ${
                alertText ? "alertBackground" : ""
              }`}
            >
              {alertText}
            </div>
            {isLoading && (
              <div id="spinner-container">
                <ClipLoader loading={isLoading} size={50} color="#00bcd4" />
              </div>
            )}
            <div
              id="wrapper"
              style={{ display: isLoading ? "none" : "block" }}
              className=""
              ref={wrapperRef}
            >
              <canvas
                ref={canvas_2dref}
                style={{ border: "1px solid #cccccc" }}
                id="my_cnvs"
              ></canvas>
            </div>
            {/* )} */}
          </div>
        </div>

        <div className="mt-auto p-2 d-flex justify-content-evenly pb-4">
          <div
            className="btn-toolbar "
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <div className="btn-group me-2" role="group" aria-label="Undo">
              <button
                className=" btn btn-light icon-container  rotate-left"
                onClick={handleUndo}
              >
                <FontAwesomeIcon icon={faRotateLeft} />
              </button>
              <button
                title="Redo"
                className="btn btn-light icon-container rotate-right"
                onClick={handleRedo}
              >
                <FontAwesomeIcon icon={faRotateRight} />
              </button>
            </div>
          </div>
          <div className="btn-toolbar " role="toolbar" aria-label="Guidelines">
            <div
              className="btn-group me-2"
              role="group"
              aria-label="Guidelines setting"
            >
              <button
                title="Cutting Lines & Stitches"
                type="button"
                className={`btn btn-light ${
                  showGrid ? "btn-outline-secondary" : "btn-secondary"
                }`}
                onClick={() => {
                  handleGridChange();
                }}
              >
                <FontAwesomeIcon icon={faVectorSquare} />
              </button>
              <button
                title="Text Labels"
                type="button"
                className={`btn btn-light ${
                  showLabel ? "btn-outline-secondary" : "btn-secondary"
                }`}
                onClick={() => {
                  handleLabelVisibility();
                }}
              >
                <FontAwesomeIcon icon={faFileText} />
              </button>
              <button
                title="Folding Lines"
                type="button"
                className={`btn btn-light ${
                  showFoldingLines ? "btn-outline-secondary" : "btn-secondary"
                }`}
                onClick={() => {
                  handleFoldingLinesVisibility();
                }}
              >
                <FontAwesomeIcon icon={faGripLinesVertical} />
              </button>
            </div>
          </div>

          <div
            className="btn-toolbar d-flex align-items-center "
            role="toolbar"
            aria-label="Guidelines"
          >
            <label className="switch mr-4" title="Panning">
              <input
                type="checkbox"
                onChange={handlePanningToggle}
                checked={isPanningEnabled}
              />
              <span className="slider round"></span>
            </label>
            <div
              className="btn-group me-2"
              role="group"
              aria-label="Guidelines setting"
            >
              <button className="btn btn-light" onClick={handleZoomOut}>
                <FontAwesomeIcon
                  icon={faSearchMinus}
               
                />
              </button>

              <button className="btn btn-light" onClick={handleZoomIn}>
                <FontAwesomeIcon
                  icon={faSearchPlus}
              
                />
              </button>
            </div>
          </div>

          <div
            className="btn-group me-2"
            role="group"
            aria-label="Delete || Export"
          >
            {/* <button className="btn btn-light" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrash} />
            </button> */}
            <button
              className="btn btn-light"
              onClick={() =>
                downloadCanvasAsImage(canvas, "fabric_canvas", "png", 300)
              }
            >
              Download
            </button>   
            <RightSideBar
                canvas={canvas}
              />

          </div>
        </div>
      </div>
    </div>
  );
};

export default Sneaker;
