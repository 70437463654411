import { GUIDELINE_OVERLAY_GROUP_PANEL, OUTLINE_LAYER_TO_CLIP, OUTLINE_LAYERS_GROUP_PANEL, TEMPLATE_BASE_COLOR } from "../utils/constants";

export const showOnlyTemplateOutline = async (canvas) => {
  return new Promise((resolve, reject) => {
    console.log('---->', canvas
        .getObjects())
    const foundTemplate = canvas
      .getObjects()
      .find((item) => item.name === GUIDELINE_OVERLAY_GROUP_PANEL);
    if (foundTemplate) {
      foundTemplate._objects.forEach((obj: fabric.Object) => {
        if (obj.metatype)
          console.log("NAMES_TO_REMOVE.includes(obj.metatype)", obj.metatype);
        if (obj && OUTLINE_LAYER_TO_CLIP !== obj.metatype)
          obj.set("visible", false);
      });
      resolve(true);
    }
    else{
        reject("Guideline overlay not found")
    }
  });
};

export const toggleObjByMetaType = (canvas, NAMES_TO_REMOVE) => {
  const foundTemplate = canvas
    .getObjects()
    .find((item) => item.name === GUIDELINE_OVERLAY_GROUP_PANEL);
  if (foundTemplate) {
    foundTemplate._objects.forEach((obj: fabric.Object) => {
      if (obj.metatype)
        console.log("NAMES_TO_REMOVE.includes(obj.metatype)", obj.metatype);
      if (obj) {
        if (NAMES_TO_REMOVE.includes(obj.metatype))
          obj.set("visible", !obj.visible);
      }
    });
  }
};

const hexToRgba = (hex) => {
  var match = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.exec(hex);
  if (!match) {
    return null; // Invalid hex color
  }

  var color = match[1];
  if (color.length === 3) {
    // If it's a shorthand hex (e.g., #000), expand to six digits
    color = color
      .split("")
      .map(function (c) {
        return c + c;
      })
      .join("");
  }

  // Convert hex to RGBA
  var r = parseInt(color.substring(0, 2), 16);
  var g = parseInt(color.substring(2, 4), 16);
  var b = parseInt(color.substring(4, 6), 16);
  var a = 255; // Default alpha is fully opaque

  return [r, g, b, a];
};

export const checkIfEmptyDesignArea = async (canvas) => {
    
    return new Promise ((resolve, reject)=>{

        const panel: any = canvas.getObjects().find((obj) => obj.name === OUTLINE_LAYERS_GROUP_PANEL);

        var rgba = hexToRgba(TEMPLATE_BASE_COLOR);
        if (!rgba) {
          alert("Invalid color format");
          return;
        }
      
        var canvasEle = canvas.getElement();
        var context = canvasEle.getContext("2d");
        let xPanel = panel.left;
        let yPanel = panel.top;
        let panelWidth = panel.width;
        let panelHeight = panel.height;
      
        if (
          xPanel !== undefined &&
          yPanel !== undefined &&
          panelWidth !== undefined &&
          panelHeight !== undefined
        ) {
          var imageData = context?.getImageData(
            xPanel,
            yPanel,
            panelWidth,
            panelHeight
          );
      
          if (imageData) {
            var data = imageData.data;
      
            for (var i = 0; i < data.length; i += 4) {
              var r = data[i]; // Red component
              var g = data[i + 1]; // Green component
              var b = data[i + 2]; // Blue component
              var a = data[i + 3]; // Alpha component
      
              // Now compare the pixel's RGBA to the target RGBA
              if (rgba)
                if (
                  r === rgba[0] &&
                  g === rgba[1] &&
                  b === rgba[2] &&
                  a === rgba[3]
                ) {
                  // alert('Color '+'#000001'+ ' found at pixel index: ' + (i / 4));
                  console.log("Base Color Found!");
                  reject("Base Color Found!")
                  
                  canvas.renderAll();
                  break;
                }
            }
            resolve(true)
          }
      
          // alert('Color ' + '#000001' + ' is NOT present on the canvas.');
        }
        
    })
 
};
