import React, { useState, useRef, useEffect } from "react";
import { fabric } from "fabric";
import {
  OUTLINE_LAYER_TO_CLIP,
  OUTLINE_LAYERS_GROUP_PANEL,
  PROPS_TO_INCLUDE_IN_CANVAS,
} from "../utils/constants";
import { showOnlyTemplateOutline } from "../library/exportActions";

type RightSideProps = {
  canvas: fabric.Canvas | null;
};

const RightSideBar: React.FC<RightSideProps> = ({
  canvas,
}) => {
  const [imageData, setImageData] = useState<string | null>(null);
  const [isOverlayVisible, setOverlayVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const exportImages = async () => {
    if (canvas) {
      setLoading(true)
      canvas.discardActiveObject();
      const strDownloadMime = "image/octet-stream";
      const strMime = "image/png";
      const dpi = 300;
      canvas.clone(async (newCanvas: fabric.Canvas) => {
        try {
          const scaleFactor = dpi / 96; // Default browser DPI is 96
          // removing all other objects from canvas
          await showOnlyTemplateOutline(newCanvas);
          newCanvas.renderAll();
          // Scale up the temporary canvas
          const originalWidth = canvas.width as number;
          const originalHeight = canvas.height as number;

          // Temporarily scale the Fabric canvas
          newCanvas.setWidth(originalWidth * scaleFactor);
          newCanvas.setHeight(originalHeight * scaleFactor);
          newCanvas.setZoom(scaleFactor);
          // Draw the scaled canvas onto the temporary canvas
          newCanvas.renderAll();

          // Convert to desired format and create download link
          const dataURL = newCanvas.toDataURL({
            format: `image/png`,
            quality: 1,
          });
          let data = dataURL.replace(strMime, strDownloadMime);
          if (data) {
            setImageData(data);
          }
          setLoading(false)
        } catch (error) {
          console.log("error", error);
          setLoading(false)
        }
      }, PROPS_TO_INCLUDE_IN_CANVAS);
    }
  };

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc); // Set the image to be displayed in the overlay
    setOverlayVisible(true); // Show the overlay
  };

  const handleCloseOverlay = () => {
    setOverlayVisible(false); // Hide the overlay
    setSelectedImage(null); // Reset the selected image
  };

  return (
    <div>
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Mock Up Images
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="p-4">
            
            <div className="row">
              {loading && <div>Fetching design</div>}
              {imageData ? (
                <div
                  className="image-item col-12"
                  onClick={() => handleImageClick(imageData)}
                >
                  <img src={imageData} alt="Canvas Image" />
                </div>
              ) : (
                <div>Please Load Mockups!</div>
              )}
            </div>
            <div className="justify-content-center d-flex">
              <button className="btn btn-secondary my-3" onClick={exportImages}>
                Generate Mockups!
              </button>
            </div>
          </div>
        </div>

        {isOverlayVisible && selectedImage && (
          <div className="overlay" onClick={handleCloseOverlay}>
            <div
              className="overlay-content justify-content-center d-flex"
              onClick={(e) => e.stopPropagation()}
            >
              <img src={selectedImage} alt="Selected Canvas Image" />
            </div>
          </div>
        )}
      </div>
      <button onClick={exportImages} className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Preview</button>
    </div>
  );
};

export default RightSideBar;
