import  apiClient from './apiClient'; // Assuming you have an apiClient instance set up

// Define the response data type
interface Artwork {
  id: string;
  title: string;
  artist: string;
  imageUrl: string;
  // Add other fields as needed
}

interface ArtworkResponse {
  data: Artwork[];
}

// Fetch all artworks with improved error handling and type safety
export const fetchArtwork = async (): Promise<Artwork[]> => {
  try {
    const response = await apiClient.get<ArtworkResponse>('/artwork/all');
    return response.data as any;
  } catch (error: any) {
    // Improved error handling
    if (error.response) {
      // The server responded with a status code outside the 2xx range
      console.error('Error fetching artworks:', error.response.data);
      console.error('Status code:', error.response.status);
      throw new Error(`Error fetching artworks: ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Error: No response received:', error.request);
      throw new Error('No response received from the server');
    } else {
      // Something happened in setting up the request
      console.error('Error setting up request:', error.message);
      throw new Error(`Error setting up request: ${error.message}`);
    }
  }
};