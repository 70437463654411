import React, { useState, useEffect } from "react";
import { fabric } from "fabric";
import { fetchPatterns } from "../patternsService";
import {
  OUTLINE_LAYER_TO_CLIP,
  OUTLINE_LAYERS_GROUP_PANEL,
  TEMPLATE_BASE_COLOR,
} from "../../utils/constants";

type PatternsPanelProps = {
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;
  handleCollapse: (value: boolean) => void;
};

const PatternsPanel: React.FC<PatternsPanelProps> = ({
  canvas,
  handleCollapse,
}) => {
  const [serverImages, setServerPatterns] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<any>();

  useEffect(() => {
    const getPatterns = async () => {
      setIsLoading(true);
      try {
        const data = await fetchPatterns();
        setServerPatterns(data);
      } catch (error) {
        console.error("Failed to fetch images:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getPatterns();
  }, []);

  const handlePatterns = (imagePath) => {
    if (canvas) {
      fabric.Image.fromURL(imagePath, (img) => {
        const pattern = new fabric.Pattern({
          source: img.getElement() as HTMLImageElement,
          repeat: "repeat", // Options: 'repeat', 'repeat-x', 'repeat-y', 'no-repeat'
        });

        // Apply the pattern to the group
        const group: any = canvas
          .getObjects()
          .find((item) => item.name === OUTLINE_LAYERS_GROUP_PANEL);

        group?._objects.forEach(
          (item) =>
            item.metatype === OUTLINE_LAYER_TO_CLIP && item?.set("fill", pattern)
        );

        group.set({ fill: "pattern" });
        setGroup(group);
        canvas.renderAll();
      });
    }
  };

  const resetPattern = () => {
    group?._objects.forEach(
      (item) =>
        item.metatype === OUTLINE_LAYER_TO_CLIP &&
        item?.set("fill", TEMPLATE_BASE_COLOR)
    );
    group.set({ fill: null });
    setGroup(group);
    canvas?.renderAll();
  };

  return (
    <div className="card text-center">
      <div className="card-header">
        Patterns{" "}
        <button
          type="button"
          className="btn-close float-end"
          aria-label="Close"
          onClick={() => handleCollapse(true)}
        ></button>
      </div>
      <div className="card-body h-full">
        {group && group.fill === "pattern" && (
          <button className="btn btn-info " onClick={resetPattern}>
            Reset Patter
          </button>
        )}
        <div className="container p-3">
          {!isLoading ? (
            <div className="row">
              {serverImages?.map((image, index) => (
                <div
                  key={image.id + "_" + index}
                  className="col-12 col-sm-6 col-md-4 col-lg-4 mb-4"
                >
                  <div className="card">
                    <img
                      src={
                        process.env.REACT_APP_AWS_BASE_URL +
                        image.thumbnail_path
                      }
                      className="card-img-top img-thumbnail"
                      alt={` ${image.id}`}
                      onClick={() =>
                        handlePatterns(
                          process.env.REACT_APP_AWS_BASE_URL + image.image_path
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatternsPanel;
