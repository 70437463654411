import React, { useState } from "react";
import { fabric } from "fabric";
import ColorPicker from "../ColorPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotateLeft,
  faRotateRight,
  faTurnUp,
  faTurnDown,
  faClone,
  faLayerGroup
} from "@fortawesome/free-solid-svg-icons";
import {
  rotateLeft,
  rotateRight,
  flipImageX,
  flipImageY,
  cloneObject,
  setArtworkColor,
  moveBackward,
  moveForward,
  bringToFront,
  sendToBack,
} from "../../library/artworkActions";
import { TEMPLATE_BASE_COLOR } from "../../utils/constants";

type ArtworkToolBoxProps = {
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;
  collapsed: boolean | null;
};

const ArtworkToolbox: React.FC<ArtworkToolBoxProps> = ({
  canvas,
  activeObject,
  collapsed,
}) => {
  const [artworkColor, _setArtworkColor] = useState(TEMPLATE_BASE_COLOR);
  const [popover, setPopover] = useState(false);

  const handleFlipX = () => {
    if (canvas && activeObject instanceof fabric.Path) {
      flipImageX(canvas, activeObject);
    }
  };
  const handleFlipY = () => {
    if (canvas && activeObject instanceof fabric.Path) {
      flipImageY(canvas, activeObject);
    }
  };

  const handleRotateLeft = () => {
    if (canvas && activeObject instanceof fabric.Path) {
      rotateLeft(canvas, activeObject);
    }
  };
  const handleRotateRight = () => {
    if (canvas && activeObject instanceof fabric.Path) {
      rotateRight(canvas, activeObject);
    }
  };

  const handleClone = () => {
    if (canvas && activeObject instanceof fabric.Path) {
      cloneObject(canvas, activeObject);
    }
  };

  const handleColorChange = (newColor: string) => {
    if (canvas && activeObject instanceof fabric.Path) {
      setArtworkColor(canvas, activeObject, newColor);
      _setArtworkColor(newColor);
    }
  };

  const togglePopover = () => {
    setPopover(!popover);
  };

  const handleFront = () => {
    if (canvas && activeObject instanceof fabric.Path) {
      moveForward(canvas, activeObject);
    }
  };

  const handleBack = () => {
    if (canvas && activeObject instanceof fabric.Path) {
      moveBackward(canvas, activeObject);
    }
  };

  const handleBringToFront = () => {
    if (canvas && activeObject instanceof fabric.Path) {
      bringToFront(canvas, activeObject);
    }
  };

  const handleSendToBack = () => {
    if (canvas && activeObject instanceof fabric.Path) {
      sendToBack(canvas, activeObject);
    }
  };
  return (
    <div className="w-100 my-2 ">
      {activeObject instanceof fabric.Path && (
        <div
          className={`d-flex justify-content-center  artwork-toolbox ${
            !collapsed ? "offset-2" : null
          }`}
        >
          <div className="" >
            <button
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleRotateLeft();
              }}
            >
              <FontAwesomeIcon icon={faRotateLeft} />
            </button>
            <button
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleRotateRight();
              }}
            >
              <FontAwesomeIcon icon={faRotateRight} />
            </button>
          </div>
          <div className="" >
            <button
              title="Flip-Y"
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleFlipY();
              }}
            >
              <FontAwesomeIcon icon={faTurnUp} />
            </button>
            <button
              title="Flip-X"
              className="btn btn-outline-secondary btn-group"
              type="button"
              onClick={() => {
                handleFlipX();
              }}
            >
              <FontAwesomeIcon icon={faTurnDown} />
            </button>
          </div>
          <div className="" >
            <button
              className="btn btn-outline-secondary btn-group"
              type="button"
              style={{ backgroundColor: "white", boxShadow: "none" }}
              onClick={() => {
                handleClone();
              }}
            >
              <FontAwesomeIcon icon={faClone} />
            </button>
          </div>
          <div
            title="Text Color"
            className=""
            role="group"
            aria-label="Text Colors"
          >
            <button
              className="btn btn-outline-secondary btn-group"
              type="button"
              style={{ backgroundColor: "white", boxShadow: "none" }}
            >
              <ColorPicker color={artworkColor} onChange={handleColorChange} />
            </button>
          </div>
          <div className="" >
            <div className="popover-container">
              <button
                type="button"
                className="btn btn-outline-secondary btn-group"
                onClick={togglePopover}
              >
                <FontAwesomeIcon icon={faLayerGroup} />
              </button>
              {popover && (
                <div className="popover popover-down">
                  <div className="popover-body d-flex row">
                    <div
                      onClick={handleFront}
                      className="col-12 justify-content-center d-flex my-1 clipto-border"
                    >
                      <span>Forward</span>
                    </div>
                    <div
                      onClick={handleBack}
                      className="col-12 justify-content-center d-flex my-1 clipto-border"
                    >
                      <span>Backward</span>
                    </div>
                    <div
                      onClick={handleBringToFront}
                      className="col-12 justify-content-center d-flex my-1 clipto-border"
                    >
                      <span>Bring to front</span>
                    </div>
                    <div
                      onClick={handleSendToBack}
                      className="col-12 justify-content-center d-flex my-1 clipto-border"
                    >
                      <span>Send to back</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArtworkToolbox;
