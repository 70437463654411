import apiClient from './apiClient';

// Fetch all images
export const fetchImages = async (): Promise<any> => {
  try {
    const response = await apiClient.get('/image/all');
    return response.data; 
  } catch (error) {
    console.error('Error fetching images:', error);
    throw error;
  }
};



// Upload an image
export const uploadImage = async (image: File, name: string): Promise<any> => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('name', name);

  try {
    const response = await apiClient.post('/image/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',  
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};