import apiClient from './apiClient';

// Fetch all images
export const fetchPatterns = async (): Promise<any> => {

  try {
    const response = await apiClient.get('/pattern/all');
    return response.data; 
  } catch (error) {
    console.error('Error fetching patterns:', error);
    throw error;
  }
};

