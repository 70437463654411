import React, { useState, useEffect } from "react";

import { fabric } from "fabric";
import WebFont from "webfontloader";
import { addText } from "../../library/textActions";
import { getGoogleFonts } from "../../library/canvasFonts";
import { POPULAR_FONTS } from "../../utils/constants";

type TextPanelProps = {
  handleCollapse:(value:boolean)=>void;
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;
};


const TextPanel: React.FC<TextPanelProps> = ({ canvas, activeObject,handleCollapse }) => {
  const [fonts, setFonts] = useState(POPULAR_FONTS);
  const [googleFonts, setGoogleFonts] = useState([]);
  const [fontFamily, _setFontFamily] = useState(
    (activeObject as fabric.Textbox)?.fontFamily || "Arial"
  );

  useEffect(() => {
    getGoogleFonts().then((fonts) => {
      setGoogleFonts(fonts);
    });

    WebFont.load({
      google: {
        families: [...fonts, ...googleFonts].map((font) => font[1]),
      },
      active: () => {
        console.log("Fonts are loaded!");
      },
    });
  }, []);

  // useEffect(() => {
  //   if (activeObject && activeObject instanceof fabric.Textbox) {
  //     _setFontFamily(activeObject.fontFamily || "Helvetica");
  //   }
  // }, [activeObject]);

  const handleTextClick = (fontFamily: string) => {
    if (canvas) {
      // if (activeObject && activeObject instanceof fabric.Textbox) {
      //   setFontFamily(canvas, activeObject, fontFamily);
      //   _setFontFamily(fontFamily);
      // }

      addText(canvas, fontFamily, {
        fontSize: 28,
        left: 150,
        top: 100,
        fill: "red",
        originX: "center",
        originY: "center",
        textAlign: "center",
        fontFamily: fontFamily,
        clipPath: undefined,
      });
    }
  };

  return (
    <div>
      <div className="card text-center">
        <div className="card-header">
          Texts & Fonts <button type="button" className="btn-close float-end" aria-label="Close" onClick={()=>handleCollapse(true)} ></button>
        </div>
        <div className="card-body">

        <div className="font-content p-4">
          <div className="list-group">
            {[...fonts, ...googleFonts].map((family, index) => {
              return (
                <li
                  key={index}
                  style={{ fontFamily: family[0] }}
                  onClick={() => handleTextClick(family[1])}
                  className="list-group-item list-group-item-action"
                >
                  {family[1]}
                </li>
              );
            })}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default TextPanel;
