import { fabric } from "fabric";
interface ImageOption {
  left?: number;
  top?: number;
  scaleX?: number;
  scaleY?: number;
  clipPath?: fabric.Object;
}

// Function to load and add SVG to the canvas
export const addSvgFromUrl = (canvas: fabric.Canvas, imagePath: string) => {
  const svgRegex = /\.svg$/i;
  if(canvas.width == null){
    return null
  }
  if (svgRegex.test(imagePath)) {
    fabric.loadSVGFromURL(imagePath, (objects, options) => {
      const svgObject = fabric.util.groupSVGElements(objects, options);
      if (canvas && svgObject && svgObject.width) {
        const canvasWidth = canvas.width || 800; // Default canvas width if null
        const canvasHeight = canvas.height || 600; // Default canvas height if null
        
        // Scaling logic: Fit to 10% of canvas width
        const scaleFactor = (canvasWidth * 0.1) / svgObject.width;
        svgObject.set({
          scaleX: scaleFactor,
          scaleY: scaleFactor,
          top: canvasHeight / 2,  // Center vertically
          left: canvasWidth / 2,  // Center horizontally
          originX: "center",
          originY: "center",
        });

        canvas.add(svgObject);
        canvas.renderAll();
      }
    }, (error) => {
      console.error("Error loading SVG:", error);
    });
  }
};

// Function to load and add image to the canvas
export const addImageFromUrl = (
  canvas: fabric.Canvas, 
  imageUrl: string, 
  options?: ImageOption
) => {
  fabric.util.loadImage(imageUrl, (url) =>{
    var object = new fabric.Image(url);
    object.set({
      width: 100,
      height: 100,
      left: 20,
      top: 30
    });
    canvas.add(object).renderAll();
  }, null, 'anonymous')

  fabric.Image.fromURL(imageUrl, (img) => {
    const canvasWidth = canvas.width || 800; // Default canvas width if null
    const canvasHeight = canvas.height || 600; // Default canvas height if null
    
    const mergedOptions = { 
      left: canvasWidth / 2,  // Default center horizontally
      top: canvasHeight / 2,  // Default center vertically
      scaleX: 1,               // Default scaling
      scaleY: 1,               // Default scaling
      ...options 
    };

    // Set options from merged configuration
    img.set({
      left: mergedOptions.left,
      top: mergedOptions.top,
      scaleX: mergedOptions.scaleX,
      scaleY: mergedOptions.scaleY,
      clipPath: mergedOptions.clipPath,
      originX: "center",
      originY: "center",
    });

    canvas.add(img);
    canvas.requestRenderAll();
  }, { crossOrigin: "anonymous" });
};

// Function to decide whether to load an SVG or an image based on file extension
export const addImageOrSvg = (canvas: fabric.Canvas, filePathUrl: string) => {
  const svgRegex = /\.svg$/i;

  if (svgRegex.test(filePathUrl)) {
    addSvgFromUrl(canvas, filePathUrl);
  } else {
    addImageFromUrl(canvas, filePathUrl);
  }
};


export const flipImageHorizontally = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  if (activeObject instanceof fabric.Image) {
    activeObject.flipX = !activeObject.flipX;
    canvas.requestRenderAll();
  }
};

export const flipImageVertically = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  if (activeObject instanceof fabric.Image) {
    activeObject.flipY = !activeObject.flipY;
    canvas.requestRenderAll();
  }
};

export const flipImageX = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  if (activeObject instanceof fabric.Image) {
    activeObject.set("flipX", !activeObject.flipX);
    canvas.renderAll();
  }
};

export const flipImageY = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  if (activeObject instanceof fabric.Image) {
    activeObject.set("flipY", !activeObject.flipY);
    canvas.renderAll();
  }
};

export const rotateLeft = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  if (activeObject.angle || activeObject.angle === 0) {
    activeObject.set("angle", activeObject.angle - 20);
    canvas.renderAll();
  }
};

export const rotateRight = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  if (activeObject.angle || activeObject.angle === 0) {
    activeObject.set("angle", activeObject.angle + 20);
    canvas.renderAll();
  }
};

export const cloneObject = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  if (activeObject) {
    activeObject.clone(function (obj) {
      var vobj = obj;
      if (vobj && activeObject.top !== undefined) {
        vobj.set({
          top: activeObject.top + 30,
        });
        canvas.add(vobj);
        canvas.renderAll();
        canvas.calcOffset();
      }
    });
    canvas.renderAll();
  }
};

export const moveBackward = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  if (canvas) {
    let ob = canvas.getActiveObject();
    if (ob) canvas.sendBackwards(ob);
    // activeObject.setCoords();
    canvas.renderAll();
  }
};
export const moveForward = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  let ob = canvas.getActiveObject();
  if (ob) canvas.bringForward(ob);
  canvas.renderAll();
};

export const bringToFront = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  canvas.bringToFront(activeObject);
  canvas.renderAll();
};

export const sendToBack = (
  canvas: fabric.Canvas,
  activeObject: fabric.Image
) => {
  canvas.sendToBack(activeObject);
  canvas.renderAll();
};


export const deleteSelectedObject = (canvas:fabric.Canvas,activeObject:fabric.Image) => {
  if (canvas) {
    canvas.remove(activeObject);
  
  }
};